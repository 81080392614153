import React, { Component } from 'react';

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';


class MapContainer extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Map
                google={this.props.google}
                zoom={14}
                initialCenter={this.props.center}
                disableDefaultUI={true}
                containerStyle={{
                    position: 'relative',
                    width: '100%',
                    height: this.props.height,
                    transition: 'height 1.5s',
                }}>
                <Marker
                    title={'House'}
                    name={'House'}
                    position={this.props.center}
                    icon={{
                        url: "/images/home.svg",
                        anchor: new this.props.google.maps.Point(16, 16),
                        scaledSize: new this.props.google.maps.Size(32, 32)
                    }}
                />
            </Map>
        )
    }
}

const MapContainerWithGoogleAPI = GoogleApiWrapper({
    apiKey: ('AIzaSyCDZP4Fpud-Xd7YVPR8b7wHxFPPfOy-Gzw'),
    LoadingContainer: () => <div></div>
})(MapContainer)

export default class ProgressiveMap extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef()
        this.state = {
            staticMapSrc: '',
            showInteractiveMap: false,
            gmapsURL: `https://www.google.com/maps/search/?api=1&query=${this.props.center.lat},${this.props.center.lng}`
        }
        this.showInteractiveMap = this.showInteractiveMap.bind(this)
    }

    componentDidMount() {
        const width = this.ref.current.width

        const scale = width < 640 ? 1 : 2

        this.setState({
            staticMapSrc:
                `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCDZP4Fpud-Xd7YVPR8b7wHxFPPfOy-Gzw&center=${this.props.center.lat},${this.props.center.lng}&zoom=14&size=${width}x300&scale=${scale}`
        })
    }

    showInteractiveMap() {
        this.setState({ showInteractiveMap: true })
    }

    render() {
        return (
            <section
                onMouseDown={this.showInteractiveMap}
            >
                <div style={{
                    position: 'relative',
                    transition: 'height 1.5s',
                    height: !this.state.showInteractiveMap ? 300 : 500,
                    backgroundColor: 'darkgray'
                }}>
                    {
                        !this.state.showInteractiveMap &&
                        <div>
                            <img style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', width: 32 }} src='/images/home.svg'></img>
                            <img style={{ objectFit: 'contain' }} ref={this.ref} src={this.state.staticMapSrc} width="100%" height="300px"></img>
                        </div>
                    }
                    {
                        this.state.showInteractiveMap &&
                        <MapContainerWithGoogleAPI height="500px" center={this.props.center} zoom={this.props.zoom}></MapContainerWithGoogleAPI>
                    }
                </div>

                <a
                    target="_blank" rel="noopener noreferrer"
                    href={this.state.gmapsURL}
                    style={{
                        textAlign: 'right',
                        display: 'block',
                        color: 'white'
                    }}
                >
                    Open in GoogleMaps
                </a>
            </section>
        );
    }
}
