import React, { useState, useEffect } from "react";
import { useTranslation, Translation } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import ProgressiveMap from "../components/map";

import { graphql } from "gatsby";
import { Carousel, Card, Button, Form, Row, Col } from "react-bootstrap";
import { transformCloudinaryURL, availabilityToLocalizedString } from "../util";
import {
  isWithinInterval,
  isFuture,
  compareAsc,
  format,
  add,
  isAfter,
  differenceInDays,
  isEqual
} from "date-fns";

import closeIcon from "../images/icons/close.svg";

const Room = ({ room, onSeePhotos }) => {
  const { t, i18n } = useTranslation();

  const [availablePeriods, setAvailablePeriords] = useState([]);
  const [availability, setAvailability] = useState("");

  const formatDate = (date) => date.toLocaleDateString(
    i18n.language,
    {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  )
  const removeTime = (dateString) => dateString.split(" ")[0];


  const now = Date.now();
  const nineYearsFromNow = add(now, { years: 9 });

  const displayInterval = (start, end) => {
    const startIsNow = differenceInDays(start, now) < 1;
    const endIsNever = !end || isAfter(end, nineYearsFromNow);

    if (startIsNow && endIsNever) {
      return t('availableNow');
    }

    if (startIsNow && !endIsNever) {
      return t('until') + " " + formatDate(end)
    }

    if (!startIsNow && endIsNever) {
      return t('after') + " " + formatDate(start)
    }

    if (!startIsNow && !endIsNever) {
      return formatDate(start) + " " + t('to') + " " + formatDate(end)
    }
  };

  useEffect(() => {
    if (room.frontmatter.available_from) {
      setAvailability(
        availabilityToLocalizedString(
          t,
          i18n.language
        )(room.frontmatter.available_from)
      );
      setAvailablePeriords(
        [
          {
            start: new Date(removeTime(room.frontmatter.available_from)),
            end: null
          }
        ]
      )
      return;
    }

    if (room.frontmatter.uniplaces_id) {
      fetch("https://offer-aggregate-graphql.uniplaces.com/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
        query offerAggregate($id: ID!, $useCache: Boolean) {
            offerAggregate(id: $id, useCache: $useCache) {
              id,
              accommodation_offer {
                  availabilities {
                      standard_unitary {
                          available_periods {
                              start_date
                              end_date
                          }
                      }
                  }
              }
            }
          }
        `,
          variables: { id: room.frontmatter.uniplaces_id },
        }),
      })
        .then((response) => response.json()) // parse JSON from request
        .then((res) => {
          const available_periods = res.data?.offerAggregate?.accommodation_offer?.availabilities?.standard_unitary?.available_periods
            ?.map(({ start_date, end_date }) => {

              return {
                start: new Date(removeTime(start_date)),
                end: new Date(removeTime(end_date)),
              };
            })
            .filter((interval) => isFuture(interval.end))
            .sort(compareAsc);

          if (available_periods.length === 0) return;
          setAvailablePeriords(available_periods);

          const isAvailableNow = available_periods.some((interval) =>
            isWithinInterval(Date.now(), interval)
          );

          if (isAvailableNow) {
            setAvailability(t("availableNow"));
          } else {
            const nextAvailability = available_periods[0].start;
            const formattedDate = formatDate(nextAvailability);
            setAvailability(t("availableFrom") + " " + formattedDate);
          }
        });
    }
  }, []);

  return (
    <Card key={room.id} style={{ textAlign: "center" }}>
      <div className="aspect-ratio-box r-1600-1067">
        <img
          src={transformCloudinaryURL(room.frontmatter.photo_main, { w: 400 })}
          alt="Room"
          style={{
            width: `100%`,
          }}
          className="aspect-ratio-box-inside"
        />
      </div>
      <Card.Body>
        <Card.Title className="color-secondary">
          {t("room")} {room.frontmatter.title}
        </Card.Title>
        <Card.Text>{room.frontmatter[`phrase_${i18n.language}`]}</Card.Text>
        <details>
          <summary>{t("moreDetails")}</summary>
          <div>
            {room.frontmatter[`commodities_${i18n.language}`] && (
              <pre
                style={{
                  color: "blue",
                  display: "block",
                  fontSize: "small",
                }}
              >
                {room.frontmatter[`commodities_${i18n.language}`]}
              </pre>
            )}
            <b className="text-primary">{room.frontmatter.price}€ / month</b>
          </div>
        </details>
        <Button
          variant="link"
          style={{
            width: "100%",
            display: "block",
          }}
          onClick={() => onSeePhotos(room.frontmatter.photos)}
        >
          {t("seePhotos")}
        </Button>
      </Card.Body>
      <Card.Footer>
        {availability && (
          <details>
            <summary>
              <b className="text-primary" style={{ fontSize: "1.3em" }}>
                {availability}
              </b>
            </summary>
            <div>
              <br />
              <p> {t('roomIsAvailable')} </p>
              {availablePeriods.map((interval, i) => (
                <p key={i} style={{ color: "green" }}>
                  {displayInterval(interval.start, interval.end)}
                </p>
              ))}
            </div>
          </details>
        )}
      </Card.Footer>
    </Card>
  );
};

const PhotoModal = ({ photos, handleClose }) => {
  if (!photos || photos.length === 0) {
    return <div></div>;
  }

  if (window) {
    window.history.pushState({ modal: true }, "modal", "modal");
    window.addEventListener("popstate", handleClose);
  }

  return (
    <section
      style={{
        display: "block",
        position: "fixed",
        top: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#000000F0",
        zIndex: 16,
      }}
    >
      <button
        onClick={() => window.history.go(-1)}
        style={{
          position: "fixed",
          top: "40px",
          right: "25px",
          background: "none",
          border: "none",
          zIndex: 2,
        }}
      >
        <img src={closeIcon} alt="" width="30px" />
      </button>

      <Carousel
        style={{
          position: "absolute",
          top: "50%",
          width: "100%",
          transform: "translate(0,-50%)",
        }}
      >
        {photos.map((img) => (
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "90vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={transformCloudinaryURL(img, { w: 400 })}
                srcSet={`${transformCloudinaryURL(img, { w: 800 })} 800w,
                                         ${transformCloudinaryURL(img, {
                  w: 1600,
                })} 1600w`}
                alt="Room"
                style={{
                  maxHeight: `100%`,
                  maxWidth: "100%",
                }}
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
};

class AskToBookForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { submited: false, validated: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  async handleSubmit(e) {
    const form = e.currentTarget;
    e.preventDefault();

    this.setState({ validated: true });
    if (form.checkValidity() === true) {
      console.log("Submit Form: ", e);

      const res = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({ "form-name": "contact", ...this.state }),
      });
      if (res.ok) {
        console.log("Form successfully submitted");
        this.setState({ submited: true });
      } else {
        console.error("Form error: ", res.error);
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });

    console.log("Form: ", this.state);
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <section
            className="color-white"
            style={{ padding: "30px 0px", marginTop: 30 }}
          >
            {!this.state.submited ? (
              <div>
                <h2 style={{ textAlign: "center" }}>{t("askToBoomRoom")}</h2>
                <br />
                <Form
                  noValidate
                  validated={this.state.validated}
                  data-netlify={true}
                  name="contact"
                  onSubmit={this.handleSubmit}
                  style={{
                    maxWidth: "600px",
                    margin: "auto",
                    display: "block",
                  }}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <input
                    type="hidden"
                    name="subject"
                    value="Pedido de reserva de quarto"
                  />

                  <Form.Group controlId="property">
                    <Form.Label>{t("property")}</Form.Label>
                    <Form.Control
                      name="property"
                      readOnly
                      required
                      defaultValue={this.props.propertyName}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="room">
                    <Form.Label>{t("room")}</Form.Label>
                    <Form.Control
                      name="room"
                      as="select"
                      required
                      onChange={this.handleChange}
                    >
                      {this.props.roomNames.map((r) => (
                        <option key={r}>{r}</option>
                      ))}
                      <option>{t("notSure")}</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="name">
                    <Form.Label>{t("name")}</Form.Label>
                    <Form.Control
                      name="name"
                      onChange={this.handleChange}
                      type="text"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>{t("email")}</Form.Label>
                    <Form.Control
                      name="email"
                      onChange={this.handleChange}
                      type="email"
                      required
                    />
                    <Form.Text>{t("neverShareEmail")}</Form.Text>
                  </Form.Group>

                  <Row>
                    <Col>
                      <Form.Group controlId="arrival">
                        <Form.Label>{t("arrival")}</Form.Label>
                        <Form.Control
                          name="arrival"
                          onChange={this.handleChange}
                          type="date"
                          required
                          placeholder="Arrival"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="departure">
                        <Form.Label>{t("departure")}</Form.Label>
                        <Form.Control
                          name="departure"
                          onChange={this.handleChange}
                          type="date"
                          required
                          placeholder="Departure"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />

                  <Form.Group controlId="aboutYourself">
                    <Form.Label>{t("tellUsAboutYourself")}</Form.Label>
                    <Form.Control
                      name="aboutYourself"
                      onChange={this.handleChange}
                      as="textarea"
                      required
                      placeholder={t("aboutYourselfExamples")}
                      rows={3}
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    style={{ display: "block", marginLeft: "auto" }}
                  >
                    {t("submit")}
                  </Button>
                </Form>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <h3>{t("formSubmited")}</h3>
                <h4>{t("wellGetBackToYou")}</h4>
              </div>
            )}
            <p style={{ textAlign: "center", marginTop: 20 }}>
              {t("contactUsWhatsapp")}
            </p>
          </section>
        )}
      </Translation>
    );
  }
}

class HeroCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { groupSize: 1, groupedImgs: [] };
    this.ref = React.createRef();
  }

  groupBy(a, n) {
    let out = [];
    for (let interval = 0; interval < a.length / n; interval++) {
      let group = [];
      for (let j = 0; j < n; j++) {
        if (a[interval * n + j]) {
          group.push(a[interval * n + j]);
        }
      }
      out.push(group);
    }
    return out;
  }

  updateImgGroup() {
    let groupSize = 1;
    if (typeof window !== `undefined`) {
      if (
        window.matchMedia &&
        window.matchMedia("(min-width: 700px)").matches
      ) {
        groupSize = 2;
      }
    }
    const groupedImgs = this.groupBy(this.props.imgs, groupSize);

    this.setState({ groupSize, groupedImgs });
  }

  componentDidMount() {
    this.updateImgGroup();
    window.addEventListener("resize", () => this.updateImgGroup());
  }

  render() {
    return (
      <div ref={this.ref}>
        <Carousel className="shadow">
          {this.state.groupedImgs.map((imgGroup, interval) => (
            <Carousel.Item key={interval}>
              {imgGroup.map((img, j) => (
                <div
                  key={j}
                  style={{
                    width: `${(1 / this.state.groupSize) * 100}%`,
                    display: imgGroup.length === 1 ? "block" : "inline-block",
                    margin: imgGroup.length === 1 ? "auto" : "none",
                  }}
                >
                  <figure
                    className="aspect-ratio-box r-800-600"
                    style={{ margin: 0 }}
                  >
                    <img
                      src={transformCloudinaryURL(img, { w: 400 })}
                      srcSet={`${transformCloudinaryURL(img, { w: 800 })} 800w`}
                      alt="Property"
                      style={{
                        width: `100%`,
                        maxHeight: "100vh",
                      }}
                      className="aspect-ratio-box-inside"
                    />
                  </figure>
                </div>
              ))}
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    );
  }
}

const Banner = ({ property }) => {
  const { i18n } = useTranslation();

  let aboutHtml;
  switch (i18n.language) {
    case "pt":
      aboutHtml = property.fields.about_pt_html;
      break;

    default:
      aboutHtml = property.fields.about_en_html;
      break;
  }

  return (
    <section className="section-banner">
      <h2>{property.frontmatter.title}</h2>
      <br />
      <div
        style={{
          textAlign: "center",
          fontFamily: "inherit",
          lineHeight: "inherit",
          maxWidth: 600,
          margin: "auto",
        }}
        dangerouslySetInnerHTML={{ __html: aboutHtml }}
      ></div>
    </section>
  );
};

const PropertyTemplate = ({ data }) => {
  const [photoModalPhotos, setPhotoModalPhotos] = useState([]);

  const property = data.property;
  const rooms = data.room.nodes;
  const location = JSON.parse(property.frontmatter.location)["coordinates"];

  const siteUrl = data.site.siteMetadata.siteUrl;
  const pageSlug = data.property.fields.slug;

  return (
    <Layout>
      <Helmet>
        <link rel="alternate" hreflang="en" href={siteUrl + "" + pageSlug} />
        <link rel="alternate" hreflang="pt" href={siteUrl + "/pt" + pageSlug} />
      </Helmet>

      <HeroCarousel imgs={property.frontmatter.photos}></HeroCarousel>

      <main>
        <Banner property={property}></Banner>
        <br />
        <ProgressiveMap
          center={{ lat: location[1], lng: location[0] }}
          zoom={15}
        ></ProgressiveMap>
        <br />

        <section
          className="grid-container-responsive"
          style={{ marginTop: 30 }}
        >
          {rooms.map((r) => (
            <Room
              room={r}
              key={r.frontmatter.title}
              onSeePhotos={(photos) => setPhotoModalPhotos(photos)}
            ></Room>
          ))}
        </section>

        <AskToBookForm
          roomNames={rooms.map((r) => r.frontmatter.title)}
          propertyName={property.frontmatter.title}
        ></AskToBookForm>
      </main>

      <PhotoModal
        photos={photoModalPhotos}
        handleClose={() => setPhotoModalPhotos([])}
      ></PhotoModal>
    </Layout>
  );
};

export const query = graphql`
  query($title: String) {
    property: markdownRemark(frontmatter: { title: { eq: $title } }) {
      id
      frontmatter {
        address
        location
        photo_main
        photos
        title
      }
      fields {
        slug
        about_en_html
        about_pt_html
      }
    }

    room: allMarkdownRemark(
      filter: { frontmatter: { parent: { eq: $title }, type: { eq: "room" } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          uniplaces_id
          price
          photo_main
          photos
          available_from
          commodities_pt
          commodities_en
          phrase_en
          phrase_pt
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default PropertyTemplate;
